import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

interface SectionTitleProps
{
    title: string;
    children?: JSX.Element;
    isLeft?: boolean;
}

const SectionTitle: FunctionComponent<SectionTitleProps> = (props) =>
{
    return (
        <Row className="justify-content-center">
            <Col xs="12" className={props.isLeft ? "" : "text-center"}>
                <div className="section-title mb-4 pb-2" data-name="maintitle">
                    <h4 className="title mb-4" data-name="sectiontitle">
                        {" "}
                        {props.title}{" "}
                    </h4>
                    <p
                        className={
                            props.isLeft
                                ? "text-muted para-desc mb-0"
                                : "text-muted para-desc mx-auto mb-0"
                        }
                        data-name="sectiondesc"
                    >
                        {props.children}
                    </p>
                </div>
            </Col>
        </Row>
    );
}

export default SectionTitle;