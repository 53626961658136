import { useAppStateSelector } from '@redux/hooks';
import { useEffect } from 'react';
import { tl } from './Utils';

/**
* Hook: loads and executes external JS scripts. It's doing by adding the script to the body element.
* 
* @param scriptUrls The scripts that need to be executed.
* @param isEnabled Load the scripts or not? Needed, beacause hooks cannot be inside conditions.
* @param deps 
*/
export function useLoadExternalScripts(scriptUrls: string[], isEnabled: boolean, deps?: React.DependencyList)
{
	let scripts: HTMLScriptElement[] = [];
	
	useEffect(() => {
		if (isEnabled)
		{
			for (let scriptUrl of scriptUrls)
			{
				let script = document.createElement("script");
				script.src = scriptUrl;
				script.async = true;
				document.body.appendChild(script);
				scripts.push(script);
			}
			
			return () => {
				for (let script of scripts)
				{
					if (document.body.contains(script)) document.body.removeChild(script);
				}
			}
		}
	}, deps);
}


/**
 * Hook: translate a text to the selected app language from the Redux store.
 */
export function useTranslate()
{
    const appLanguage = useAppStateSelector(state => state.layout.language);

    const t = (text: string) => 
    {
        return tl(text, appLanguage.code);
    };

    return t;
}