import { useTranslate } from '@xFrame4/common/hooks';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface SassProps
{

}

const Sass: FunctionComponent<SassProps> = (props) =>
{
    const t = useTranslate();
    
    return (
        <section
            className="bg-home d-flex align-items-center"
            style={{
                background: `url('img/saas/home-shape.png')`,
                backgroundPosition: "center center",
                height: "auto",
            }}
            id="home"
        >
            <Container>
                <Row className="justify-content-center">
                    <Col lg="12" className="text-center mt-0 pt-0">
                        <div className="title-heading margin-top-100">
                            <h1 className="heading mb-3">
                                {t('SASS_TITLE')}
                            </h1>
                            <p className="para-desc mx-auto text-muted">
                                {t('SASS_DESCRIPTION')}
                            </p>
                        </div>
                        <div className="home-dashboard">
                            <img src="img/saas/home.png" alt="" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Sass;